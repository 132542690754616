import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DEFAULT_PAGINATION,
  dirNameS3,
} from "../../../constants/app-constants";
import { postApplyJob } from "../../../redux/slices/applyJob";
import { getDistrictList } from "../../../redux/slices/district";
import { getProvinceList } from "../../../redux/slices/province";
import { dispatch, useSelector } from "../../../redux/store";
import { validateAge } from "../../../utils/schema";
import Utils from "../../../utils/utils";

type Props = {
  isModalOpen: boolean;
  careerCurrent?: any;
  handleClose: () => void;
};

const ModalApplyCareer = ({
  isModalOpen,
  handleClose,
  careerCurrent,
}: Props) => {
  const [form] = Form.useForm();
  const { provinceList } = useSelector((state) => state.province);
  const { districtList } = useSelector((state) => state.district);

  const { t } = useTranslation();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [arrFile, setArrFile] = useState<any[]>([]);
  const onFinish = async (data: any) => {
    setLoading(true);

    try {
      const url = await Utils.uploadFile(
        arrFile[0].originFileObj,
        dirNameS3.applyCareer,
        `Đơn ứng tuyển - ${data.fullName}`
      );
      const submitData = {
        ...data,
        dateOfBirth: data.dateOfBirth.format("MM-DD-YYYY"),
        career_id: careerCurrent,
        url: await url,
      };
      await dispatch(postApplyJob(submitData));
    } finally {
      setLoading(false);
      handleClose();
    }
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setArrFile([]);
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    if (arrFile.length > 0) {
      setDisabled(false);
    }
  }, [arrFile]);
  useEffect(() => {
    dispatch(
      getProvinceList({
        pageSize: DEFAULT_PAGINATION.getAll,
        pageIndex: DEFAULT_PAGINATION.pageIndex,
      })
    );
  }, []);
  const handleChange = async (value: string | number) => {
    await dispatch(
      getDistrictList({
        pageSize: DEFAULT_PAGINATION.getAll,
        pageIndex: DEFAULT_PAGINATION.pageIndex,
        provinceId: value,
      })
    );
    await form.setFieldValue("district_id", "");
  };

  return (
    <>
      <Modal
        width={586}
        footer={null}
        open={isModalOpen}
        onOk={handleClose}
        onCancel={handleClose}
        className="jobApplyModal"
      >
        <Form form={form} name="formJob" onFinish={onFinish}>
          <Row className="formApplyJob">
            <Col xs={24} className="jobApplyModal__title">
              {t("homepage.modal.apply")}
            </Col>
            <Col xs={24}>
              <div className="formApplyJob__label">
                {t("homepage.modal.fullName")}
              </div>
              <Form.Item
                name="fullName"
                rules={[
                  { required: true, message: "Please input your fullname!" },
                ]}
              >
                <Input
                  className="formApplyJob__input"
                  placeholder={t("homepage.modal.fullName")}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                  <div className="formApplyJob__label">
                    {t("homepage.modal.birthDate")}
                  </div>
                  {/* <Form.Item
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your date of birth!",
                      },
                      {
                        validator: validateAge,
                      },
                    ]}
                  >
                    <DatePicker
                      className="formApplyJob__input"
                      id="birthDate"
                      name="birthDate"
                      placeholder="dd/mm/yyyy"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item> */}
                  <Form.Item
                    name="dateOfBirth"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your date of birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      className="formApplyJob__input"
                      id="birthDate"
                      name="birthDate"
                      placeholder="dd/mm/yyyy"
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div className="formApplyJob__label">
                    {t("homepage.modal.phoneNumber")}
                  </div>
                  <Form.Item
                    name="phoneNumber"
                    rules={[
                      {
                        required: true,
                        message: "Please input your phone!",
                      },
                      {
                        pattern: /^0\d{9}$/,
                        message:
                          "Please enter 10 numbers correctly and start with 0!",
                      },
                    ]}
                    // validateTrigger={["onSubmit"]}
                  >
                    <Input
                      className="formApplyJob__input"
                      // placeholder={t("homepage.modal.phoneNumber")}
                      addonBefore="(+084)"
                      placeholder="_ _ _ _ _ _ _ _ _ _"
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row gutter={[12, 0]}>
                <Col xs={24} md={12}>
                  <div className="formApplyJob__label">
                    {t("homepage.modal.province")}
                  </div>
                  <Form.Item
                    name="province_id"
                    rules={[
                      {
                        required: true,
                        message: "Please choose province!",
                      },
                    ]}
                  >
                    <Select
                      onChange={handleChange}
                      virtual={false}
                      options={
                        provinceList &&
                        provinceList?.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <div className="formApplyJob__label">
                    {t("homepage.modal.district")}
                  </div>
                  <Form.Item
                    name="district_id"
                    rules={[
                      {
                        required: true,
                        message: "Please choose district!",
                      },
                    ]}
                    // validateTrigger={["onSubmit"]}
                  >
                    <Select
                      virtual={false}
                      options={
                        districtList &&
                        districtList?.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24}>
              <div className="formApplyJob__label">
                {t("homepage.modal.experience")}
              </div>
              <Form.Item
                name="experience"
                rules={[
                  { required: true, message: "Please input your experience!" },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Please input a valid number!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const numericValue = parseInt(value, 10);
                      if (numericValue >= 0 && numericValue < 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Please input a number between 0 and 100!")
                      );
                    },
                  }),
                ]}
              >
                <Input className="formApplyJob__input" placeholder="number" />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div className="formApplyJob__label">
                {t("homepage.modal.resume")}
              </div>
              <Form.Item  name="upload"
                    rules={[
                      {
                        required: true,
                        message: "Please upload file!",
                      },
                    ]}>
              <Upload
                accept=".doc,.docx,.pdf"
                maxCount={1}
                beforeUpload={() => false}
                fileList={arrFile}
                onChange={(file) => {
                  setArrFile(file.fileList);
                }}
              >
                <Button className="formApplyJob__upload" size="large">
                  <UploadOutlined />
                  {t("homepage.modal.upload")}
                </Button>
              </Upload>
              </Form.Item>
             
              <div className="formApplyJob__support">
                Support file type: docx, pdf
              </div>
            </Col>
            <Col xs={24}>
              <Button
                htmlType="submit"
                size="large"
                className="formApplyJob__submit"
                loading={loading}
              >
                {t("homepage.modal.send")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalApplyCareer;
