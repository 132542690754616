import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import "./home.scss";
import banner from "../../assets/images/layout.png";
import { useTranslation } from "react-i18next";
import ButtonCustom from "../../components/button/ButtonCustom";
import CarouselPartner from "../../components/views/home-page/carousel-partner/CarouselPartner";
import HeadlineComponent from "../../components/views/home-page/headline-component/HeadlineComponent";
import News from "../../components/views/home-page/news/News";
import OurService from "../../components/views/home-page/our-service/OurService";
import JobOffer from "../../components/views/home-page/receiving-job-offer/JobOffer";
import ChooseUS from "../../components/views/home-page/why-choose-us/ChooseUS";
import BannerComponent from "../../components/banner-component/BannerComponent";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/url-config";
import { useEffect,useState } from "react";
import { DEFAULT_PAGINATION } from "../../constants/app-constants";
import { dispatch, useSelector } from "../../redux/store";
import { getHighlight } from "../../redux/slices/career";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";
import { IHomePage } from "../../@types/homePage";
import MetaLayout from "../../components/views/layout/meta-layout";

interface Props extends RouteChildrenProps, LayoutProps { }

export default function HomePage(props: Props) {
  const { t } = useTranslation();
  const { home } = useSelector((state) => state.webcontent);
  const [metaData, setMetaData] = useState<any>(null); // State cho metaData

  useEffect(() => {
    if (home && home.length > 0) {
      const dataHome: IHomePage = JSON.parse(home[0].content);
      const newMetaData = {
        title: dataHome.banner.title,
        description: dataHome.banner.content,
        ogImage: dataHome.banner.backgroundUrl,
        keywords: "",
        ogTitle: dataHome.banner.title,
        ogDescription: dataHome.banner.content,
        ogUrl: window.location.href,
      };
      console.log("newMetaData",newMetaData);
      
      setMetaData(newMetaData);
    }
  }, [home]);

  useEffect(() => {
    if (!home) {
      dispatch(getWebcontentPage(WebcontentType.Home));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      getHighlight({
        pageSize: DEFAULT_PAGINATION.pageSize,
        pageIndex: DEFAULT_PAGINATION.pageIndex,
        isHighlight: true,
      })
    );
  }, []);

  return (
    <>
      {metaData && <MetaLayout metaData={metaData} />}
      {home && home.length > 0 && (
        <>
          {home.map((item) => {
            const dataHome: IHomePage = JSON.parse(item.content);
            return (
              <div key={item.id}>
                <BannerComponent
                  isHomePage
                  title={dataHome.banner.title}
                  content={dataHome.banner.content}
                  bannerUrl={dataHome.banner.backgroundUrl}
                  button={
                    <Link to={ROUTE_PATHS.Contact}>
                      <ButtonCustom title={t("getInTouch")} />
                    </Link>
                  }
                />
                <section className="sectionContainer">
                  <ChooseUS
                    content={dataHome.whyChooseUs.content}
                    image={dataHome.whyChooseUs.image}
                    subtitle={dataHome.whyChooseUs.subtitle}
                    title={dataHome.whyChooseUs.title}
                  />
                </section>
                <section className="sectionContainer ourServiceSection">
                  <OurService />
                </section>
                <section className="sectionContainer jobOfferSection">
                  <JobOffer
                    content={dataHome.jobOffer.content}
                    image={dataHome.jobOffer.image}
                    subtitle={dataHome.jobOffer.subtitle}
                    title={dataHome.jobOffer.title}
                    outstandingJobId={dataHome.jobOffer.outstandingJobId}
                  />
                </section>
                <HeadlineComponent />
                <section className="sectionContainer">
                  <News title={dataHome.news.title} content={dataHome.news.content} />
                </section>
                <CarouselPartner partner={dataHome.partner} />
              </div>
            );
          })}
        </>
      )}
    </>
  );
}