import React from "react";
import "./CardComponent.scss";
import picture1 from "../../assets/images/home-page/card.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_PATHS } from "../../constants/url-config";
import { Skeleton, Typography } from 'antd';
const { Paragraph } = Typography

type Props = {
  title?: string;
  picture?: any;
  content?: string;
  href?: string;
  id: number | string;
};

const CardComponent = ({ title, picture, content, href, id }: Props) => {
  const { t } = useTranslation();
  const loading = !id;
  // return (
  //   <div className="cardCustom">
  //     <div className="cardCustom__image">
  //     {href && (
  //         <Link to={href} className="image-link">
  //           <img src={picture || picture1} alt="Card" className="card-image" />
  //         </Link>
  //       )}
  //     </div>
  //     <h3 className="cardCustom__title">{title}</h3>
  //     <div className="lineCard"></div>
  //     <div className="cardCustom__content">{content}</div>
  //     {href && (
  //       <Link to={href} className="cardCustom__readMore">
  //         {t("readDetails")}
  //       </Link>
  //     )} 
  //   </div>
  // );
  return (
    <div className="article-card">
     {loading ? (
        <Skeleton.Image className="article-card-skeleton-img" active={true} />
      ) : (
        href && (
          <Link to={href}>
            <div>
              <div className="article-card-img-wrapper">
                <img className="article-card-img" src={picture || picture1} alt="Card" />
              </div>
              <Skeleton loading={loading} active={true}>
                <div className="article-card-content">
                  <div className="article-card-title">
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: false,
                      }}
                    >
                      {title}
                    </Paragraph>
                  </div>
                  <div className="article-card-desc">
                    <Paragraph
                      ellipsis={{
                        rows: 3,
                        expandable: false,
                      }}
                    >
                      {content}
                    </Paragraph>
                  </div>
                </div>
              </Skeleton>
            </div>
          </Link>
        )
      )}
    </div>
  );
};

export default CardComponent;
