import { useParams } from 'react-router';

import { useEffect } from 'react';
import { dispatch, useSelector } from '../../redux/store';
import "./ServiceDetails.scss";
import ReactHtmlParser from 'react-html-parser';
import ContentComponent from '../../components/content-component/ContentComponent';
import BannerComponent from '../../components/banner-component/BannerComponent';
import { useTranslation } from 'react-i18next';
import { getOneEvent, getServiceEvent } from '../../redux/slices/event';
import BreadcrumbHeader from '../../components/breadcrumb/Breadcrumb';
import { Col, Row, Typography } from "antd";
import CardComponent from '../../components/card-component/CardComponent';
import { ROUTE_PATHS } from '../../constants/url-config';
import { SERVICE } from '../../constants/app-constants';
import moment from 'moment';
import CareersDetail from '../careers-detail/CareersDetail';


const { Title, Text } = Typography;

type Props = {};

const ServiceDetail = (props: Props) => {
  const {eventDetails} = useSelector(state=>state.event)
  const {services} = useSelector(state=>state.event)
  
  const params = useParams<{id: any}>()
  useEffect(()=> {
    if(params) {
      dispatch(getOneEvent(params.id))
    }
  }, [params])
  const {t} = useTranslation();
  return (
    <>
    <BannerComponent 
     title={t("serviceDetailPage.banner.title")}
     subtitle={t("serviceDetailPage.banner.subtitle")}
   />
   <BreadcrumbHeader />
   <>
  <div className='serviceDetail containerCustom'>
      <Title level={2} data-aos="fade-up" data-aos-duration="700" className='title serviceDetail__title'>{eventDetails.name}</Title>
      <div className='serviceDetail__date' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
      {moment(eventDetails.createdAt).format("DD-MM-YYYY")}
      </div>
      <div className='serviceDetail__desc' data-aos="fade-up" data-aos-duration="700" data-aos-delay="300">
      {eventDetails.description}
        </div>
      <div className='serviceDetail__content' data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">{ReactHtmlParser(eventDetails.content)}</div>
      {/* <div>
      <h2 className='serviceDetail__morenews'>{t("servicesPage.content.morenews")}</h2>
      <Row gutter={[{ xl: 20 }, { xl: 0, xs: 30 }]} className="serviceDetail__content">
      {services.slice(3).map(services => (
        <Col key={services.id} xs={24} xl={8}>
          <CardComponent
            title={services.name}
            content={services.description}
            picture={services.thumbnail} 
            id={services.id} 
            href={ROUTE_PATHS.ServiceDetail.replace(":id", services.id.toString())}
          />
        </Col>
      ))}
      </Row>
      </div> */}
      </div>
    </>
   </>
  );
};

export default ServiceDetail;
