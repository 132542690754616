
import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import { Col, Row } from "antd";
import { Typography } from "antd";
import './Contacts.scss';
import { useTranslation } from "react-i18next";
import BannerComponent from "../../components/banner-component/BannerComponent";
import Feedback from "../../components/views/contact/FeedbackForm";
import BreadcrumbHeader from "../../components/breadcrumb/Breadcrumb";
import useResponsive from "../../hooks/useResponsive";
import { dispatch, useSelector } from "../../redux/store";
import { IContactPage } from "../../@types/contactPage";
import { useEffect } from "react";
import { getWebcontentPage } from "../../redux/slices/webcontent";
import { WebcontentType } from "../../@types/webcontent";
import MetaLayout from "../../components/views/layout/meta-layout";
import zaloIcon from "../../assets/icon/footer/zalo.svg";
import { IFooterPage } from "../../@types/footerPage";
const { Title } = Typography;
interface Props extends RouteChildrenProps, LayoutProps { }

export default function ContactUsPage(props: Props) {
  const { t } = useTranslation();
  const { contact } = useSelector((state) => state.webcontent);
  const { footer } = useSelector((state) => state.webcontent);
  let dataContact: IContactPage | null = null;
  let dataFooter: IFooterPage | null = null;
  if (contact && contact.length > 0) {
    dataContact = JSON.parse(contact[0].content);
  }
  if (footer && footer.length > 0) {
    dataFooter = JSON.parse(footer[0].content);
  }
  useEffect(() => {
    if (!contact) {
      dispatch(getWebcontentPage(WebcontentType.Contact));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const metaData = dataContact
  ? {
      title: dataContact.banner.title,
      description: dataContact.banner.content,
      ogImage: dataContact.banner.backgroundUrl,
      keywords: "",
      ogTitle: dataContact.banner.title,
      ogDescription: dataContact.banner.content ,
      ogUrl: window.location.href,
    }
  : null;

  return (
    <>
    {metaData && <MetaLayout metaData={metaData} />}
    <div className="contact">
      <BannerComponent
        title={dataContact?.banner.title ? dataContact?.banner.title : t("contactPage.banner.title")}
        subtitle={dataContact?.banner.content ? dataContact?.banner.content : t("contactPage.banner.subtitle")}
        bannerUrl={dataContact?.banner.backgroundUrl ? dataContact?.banner.backgroundUrl : ""}
      />
      <BreadcrumbHeader />
    <div className="containerCustom">
      <Row gutter={{ xs: 0, sm: 20, md: 20, lg: 40 }} className="contactPage">
        <Col span={12} data-aos="fade-up"
          data-aos-anchor-placement="top-bottom" data-aos-duration="700">
          <Feedback />
        </Col>
        <Col span={12} data-aos="fade-down" 
          data-aos-anchor-placement="top-bottom" data-aos-duration="700">
          <div className="contactPage__right">
            <Title level={5}>
              {dataContact?.feedback ? dataContact?.feedback : t("contactPage.banner.content")}
            </Title>
          </div>
          <div className="map">
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.7626314463024!2d106.7129659!3d10.8443141!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752900020e1b1f%3A0xaa9cd0021d5d00c1!2sC%C3%B4ng%20Ty%20TNHH%20AGARI!5e0!3m2!1svi!2s!4v1695832618412!5m2!1svi!2s"
              width="100%" 
              height="300" 
              style={{ border: 0 }} 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps location of our address"
            />
          </div>
          <div className="form">
        <div className="contact-info">
          <div className="info">
            <div className="information">
              <i className="fas fa-map-marker-alt"></i> 
              <p>44 Dinh Thi Thi Street, Hiep Binh Phuoc Ward, Thu Duc City, Ho Chi Minh.</p>
            </div>
            <div className="information">
              <i className="fas fa-phone"></i>
              <a href="tel:0976888624">0976888624</a>
            </div>
          </div>
          <div className="social-media">
            <p>{t("contactPage.feedback.contact")}</p>
            <div className="social-icons">
              <a target="_blank"  href={dataFooter?.linkFacebook} rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a target="_blank" href={dataFooter?.linkZalo} rel="noopener noreferrer">
              <img src={zaloIcon} style={{height : '25px'}} alt="zalo" />
            </a>
            <a target="_blank"  href={dataFooter?.linkTiktok} rel="noopener noreferrer">
            <i className="fab fa-tiktok"></i>
              </a>
              <a target="_blank"  href='https://www.linkedin.com/company/agari-hr-outsourcing/' rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
    </div>
        </Col>
      </Row>
    </div>
    </div>
    </>
  );
}
