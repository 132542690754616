import { Carousel, Col, Row } from "antd";
import { IChooseHomePage } from "../../../../@types/homePage";
import picture from "../../../../assets/images/home-page/Rectangle 91.png";
import './ChooseUs.scss';

const ChooseUS = ({
  title,
  content,
  image,
  subtitle,
}: IChooseHomePage) => {
  const images = [{url : image}, {url : 'https://web-agari.s3-ap-southeast-1.amazonaws.com/agari/images/1727927253006-NQH00005.jpg.jpg'},{url : "https://web-agari.s3-ap-southeast-1.amazonaws.com/agari/images/1727927253968-NQH09905.jpg.jpg"}]
  return (
    <div className="containerCustom">
      <Row className="chooseUs" gutter={[{ xs: 0, xl: 80 }, { xs: 24, xl: 0 }]}>
        <Col xs={24} xl={12}>
          <div data-aos="fade-up"
            data-aos-duration="700" className="chooseUs__image">
           <Carousel 
            className="chooseUs__carousel"
            autoplay
            autoplaySpeed={3000}
            infinite={true}
            effect="fade" 
            dots={true} 
            easing="ease-in-out" 
           >
          {images.map((img, index) => (
            <div key={index} className="shine-effect no-hover">
              <img src={img?.url} alt={"chooseUs__image"} />
            </div>
          ))}
        </Carousel>
        </div>
        </Col>
        <Col xs={24} xl={12}>
          <div data-aos="fade-down"
            data-aos-duration="700" className="chooseUs__content">
            <h2 className="chooseUs__content--title">{title ? title : 'Why choose us?'}</h2>
            <h3 className="title__content">
              <div dangerouslySetInnerHTML={{ __html: subtitle }} />
            </h3>
            <Row gutter={[{ xs: 0, xl: 140 }, { xs: 24, xl: 32 }]} className="countCompanies">
              <Col xs={8}>
                <div className="count">
                  <div className="count__title">{content[0].title ? content[0].title : 1000}</div>
                  <div className="count__content">{content[0].content ? content[0].content : 'Companies We Helped'}</div>
                </div>
              </Col>
              <Col xs={8}>
                <div className="count">
                  <div className="count__title">{content[1].title ? content[1].title : 30}</div>
                  <div className="count__content">{content[1].content ? content[1].content : 'Trainings Courses'}</div>
                </div>
              </Col>
              <Col xs={8}>
                <div className="count">
                  <div className="count__title">{content[2].title ? content[2].title : 20}</div>
                  <div className="count__content">{content[2].content ? content[2].content : 'Corporate Programs'}</div>
                </div>
              </Col>
              <Col xs={8}>
                <div className="count">
                  <div className="count__title">{content[3].title ? content[3].title : 50}</div>
                  <div className="count__content">{content[3].content ? content[3].content : 'Corporate Programs'}</div>
                </div>
              </Col>
              <Col xs={8}>
                <div className="count">
                  <div className="count__title">{content[4]?.title}</div>
                  <div className="count__content">{content[4]?.content}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChooseUS;
